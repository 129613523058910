import { useState } from "react";

import "./PermissionCheckBox.css"

export interface PermissionCheckBoxProps {
	label: string,
	checked: boolean,
	id: string | number,
	callback: Function
}

const PermissionCheckBox = (props: PermissionCheckBoxProps) => {
	const [checked, setChecked] = useState<boolean>(props.checked);

	const onCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		props.callback(event.target.checked, props.id)
	}

	return (
		<div className="permissionCheckboxContainer">
			<input className="permissionCheckbox" type="checkbox" checked={checked} onChange={(e) => onCheck(e)} />
			<p className="permissionLabel">{props.label}</p>
		</div>
	);
}

export default PermissionCheckBox;