import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import Layout from './Layout';
import Home from './Pages/Home/Home';
import NotFound from './Pages/NotFound/NotFound';
import "./Layout.css"
import PrivacyPolicy from './Pages/Legal/PrivacyPolicy';
import Rules from './Pages/Legal/Rules';
import Login from './Pages/user/Login';
import Logout from "./Pages/user/Logout";
import UserTable from "./Pages/user/UserTable";
import ViewUser from "./Pages/user/ViewUser";
import GuideTable from "./Pages/guides/GuideTable";
import ViewGuide from "./Pages/guides/ViewGuide";
import TermsAndConditions from "./Pages/Legal/TermsAndConditions";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="legal/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="legal/termsandconditions" element={<TermsAndConditions />} />
        <Route path="rules" element={<Rules />} />
        <Route path="guides/" element={<GuideTable />} />
        <Route path="guides/guide" element={<ViewGuide />} />
        <Route path="users/" element={<UserTable />} />
        <Route path="users/user" element={<ViewUser />} />
        <Route path="users/login" element={<Login />} />
        <Route path="users/logout" element={<Logout />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);