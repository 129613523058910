import "./Legal.css"

const TermsAndConditions = () => {
	return (
		<div className="legal-container">
			<h1>Terms and Conditions</h1>

			<ol className="legal-list">
				<li>
					<p className="legal-title">Introduction</p>
					<p className="legal-description">
						These Terms and Conditions govern your use of our Discord server, FiveM servers, and websites operated by Residual Developments LTD (“we,” “us,” or “our”).
					</p>
					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								Websites: Refers to any official ResidualRP website or forum.
							</p>
						</li>
						<li>
							<p className="legal-description">
								FiveM Servers: Any servers created within FiveM under Cfx associated with ResidualRP.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Services: Collectively refers to our websites, Discord server, and FiveM servers.
							</p>
						</li>
						<li>
							<p className="legal-description">
								By using our Services, you agree to these Terms in full. If you disagree with any part, you must not use our Services.
							</p>
						</li>
						<li>
							<p className="legal-description">
								If you hold a position within ResidualRP, you may be required to expressly agree to these Terms by registering on the forum.
							</p>
						</li>
						<li>
							<p className="legal-description">
								You must be at least <strong>13 years old</strong> to use our Services. If you are between <strong> 13 and 18, parental or guardian consent is required </strong>. By using our Services, you confirm you meet this age requirement.
							</p>
						</li>
						<li>
							<p className="legal-description">
								During the whitelisting process, we may request proof of age. This information is handled in accordance with our Privacy Policy and is not stored.
							</p>
						</li>
					</ul>
				</li>

				<li>
					<p className="legal-title">Copyright Notices</p>
					<p className="legal-description">
						&copy; 2024, Residual Developments LTD.
					</p>
					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								We, along with our licensors, own and control all intellectual property rights in our Services and content. All rights are reserved.
							</p>
						</li>
					</ul>
				</li>

				<li>
					<p className="legal-title">Permission to Use Our Services</p>
					<p className="legal-description">
						You may:
					</p>
					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								View and download pages from our websites for personal, non-commercial use.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Stream audio and video files using our media player.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Connect to our FiveM servers using legitimate software.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Use our FiveM servers with a legitimate copy of FiveM and Grand Theft Auto V.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Download necessary server files during gameplay.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Stream our public FiveM server on third-party platforms.
							</p>
						</li>
					</ul>
					<p className="legal-description">
						You must not:
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								Download or save any material from our Services unless expressly permitted.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Use our Services solely for personal, non-commercial purposes.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Edit or modify any material on our Services unless expressly permitted.
							</p>
						</li>
					</ul>

					<p className="legal-description">
						Unless you own the rights, you must not:
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								Republish material from our Services.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Sell or sub-license material from our Services.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Exploit material for commercial purposes.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Redistribute material from our Services.
							</p>
						</li>
					</ul>
					<p className="legal-description">
						We may suspend or restrict access to our Services or certain functionalities. Do not attempt to bypass any access restrictions.
					</p>
				</li>

				<li>
					<p className="legal-title">Misuse of Our Services</p>
					<p className="legal-description">
						You must not:
					</p>
					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								Damage or impair our Services.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Use our Services unlawfully or fraudulently.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Hack or tamper with our Services.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Probe or test our Services without permission.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Circumvent security systems.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Use our Services to distribute malicious software.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Overload our resources.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Decrypt communications without permission.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Conduct automated data collection without consent.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Use data from our Services for direct marketing.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Interfere with normal use of our Services.
							</p>
						</li>
					</ul>

					<p className="legal-description">
						Do not use data from our Services to contact individuals or entities.
					</p>
					<p className="legal-description">
						Ensure all information you provide is accurate and not misleading.
					</p>
				</li>

				<li>
					<p className="legal-title">Registration and Accounts</p>
					<p className="legal-description">
						Your user ID must not be misleading and must comply with Section 7 (Content Rules). Do not impersonate others.
					</p>
					<p className="legal-description">
						Keep your password confidential.
					</p>
					<p className="legal-description">
						Notify us immediately of any unauthorized account use or password disclosure.
					</p>
					<p className="legal-description">
						You are responsible for all activities under your account.
					</p>
					<p className="legal-description">
						Do not use another person's account without approval from senior staff.
					</p>
					<p className="legal-description">
						Your obligations extend to any third-party services used to access our Services.
					</p>
					<p className="legal-description">
						We may suspend or cancel your account at any time without notice.
					</p>
				</li>

				<li>
					<p className="legal-title">Our Rights to Use Your Content</p>
					<p className="legal-description">
						"Your Content" refers to any material you submit to our Services.
					</p>
					<p className="legal-description">
						You grant us a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, and distribute your content.
					</p>
					<p className="legal-description">
						You waive all moral rights in your content to the fullest extent permitted by law.
					</p>
					<p className="legal-description">
						You may edit your content where allowed.
					</p>
					<p className="legal-description">
						We may remove or edit your content if it breaches these Terms.
					</p>
				</li>

				<li>
					<p className="legal-title">Content Rules</p>
					<p className="legal-description">
						Your content must comply with these Terms.
					</p>
					<p className="legal-description">
						Your content must not be illegal, infringe on legal rights, or lead to legal action.
					</p>
					<p className="legal-description">
						Your content must not:
					</p>
					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								Be defamatory or false.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Be obscene or indecent.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Infringe intellectual property rights.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Violate privacy or data protection laws.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Contain negligent advice.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Promote illegal activities.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Be in contempt of court.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Be discriminatory or hateful.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Be misleading or deceptive.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Constitute spam.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Be offensive or cause unnecessary inconvenience or anxiety.
							</p>
						</li>
					</ul>
				</li>

				<li>
					<p className="legal-title">Limited Warranties</p>
					<p className="legal-description">
						We do not guarantee:
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								Completeness or accuracy of information.
							</p>
						</li>
						<li>
							<p className="legal-description">
								That content is up to date.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Availability of our Services.
							</p>
						</li>
					</ul>

					<p className="legal-description">
						We may discontinue or alter our Services without notice.
					</p>

					<p className="legal-description">
						To the fullest extent permitted by law, we exclude all warranties relating to our Services.
					</p>
				</li>

				<li>
					<p className="legal-title">Limitations and Exclusions of Liability</p>
					<p className="legal-description">
						Nothing in these Terms will limit or exclude liability for:
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								Death or personal injury due to negligence.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Fraud or fraudulent misrepresentation.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Any liabilities not permitted to be limited or excluded under law.
							</p>
						</li>
					</ul>

					<p className="legal-description">
						We will not be liable for:
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								Losses arising from events beyond our control.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Business losses like lost profits or revenue.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Loss or corruption of data or software.
							</p>
						</li>
					</ul>

					<p className="legal-description">
						We will not be liable for:
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								Losses arising from events beyond our control.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Business losses like lost profits or revenue.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Loss or corruption of data or software.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Special, indirect, or consequential losses.
							</p>
						</li>
					</ul>

					<p className="legal-description">
						You agree not to hold our officers or employees personally liable.
					</p>
				</li>

				<li>
					<p className="legal-title">Breaches of These Terms</p>
					<p className="legal-description">
						If you breach these Terms, we may:
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								Issue warnings.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Suspend or terminate your access.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Block your IP address.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Contact your internet provider.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Take legal action.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Suspend or delete your account.
							</p>
						</li>
					</ul>

					<p className="legal-description">
						Do not attempt to bypass any suspension or restriction.
					</p>
				</li>

				<li>
					<p className="legal-title">Variation</p>
					<p className="legal-description">
						We may update these Terms periodically.
					</p>
					<p className="legal-description">
						Revised Terms apply from the date of publication.
					</p>
					<p className="legal-description">
						If you disagree with the changes, stop using our Services.
					</p>
				</li>

				<li>
					<p className="legal-title">Assignment</p>
					<p className="legal-description">
						We may transfer our rights and obligations under these Terms.
					</p>
					<p className="legal-description">
						You may not transfer your rights without our written consent.
					</p>
				</li>

				<li>
					<p className="legal-title">Severability</p>
					<p className="legal-description">
						If any provision is unlawful or unenforceable, the rest remain in effect.
					</p>
					<p className="legal-description">
						Unenforceable parts will be deemed deleted.
					</p>
				</li>

				<li>
					<p className="legal-title">Third-Party Rights</p>
					<p className="legal-description">
						These Terms benefit only us and you; no third party can enforce them.
					</p>
				</li>

				<li>
					<p className="legal-title">Entire Agreement</p>
					<p className="legal-description">
						These Terms and our Privacy Policy constitute the entire agreement between us regarding your use of our Services.
					</p>
				</li>

				<li>
					<p className="legal-title">Law and Jurisdiction</p>
					<p className="legal-description">
						These Terms are governed by English law.
					</p>
					<p className="legal-description">
						Disputes are subject to the exclusive jurisdiction of English courts.
					</p>
				</li>

				<li>
					<p className="legal-title">Our Details</p>
					<p className="legal-description">
						These Services are operated by Residual Developments LTD.
					</p>
					<p className="legal-description">
						We are registered in England and Wales under registration number 16005923, and our registered office is at:
					</p>
					<p className="legal-description">
						71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ
					</p>
					<p className="legal-description">
						You can contact us:
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								By post at the above address.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Via our website contact form.
							</p>
						</li>
						<li>
							<p className="legal-description">
								By email: <a href="mailto:compliance@residualrp.com">compliance@residualrp.com</a>.
							</p>
						</li>
					</ul>
				</li>
			</ol>
		</div>
	)
}

export default TermsAndConditions;