import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BannerMessage, { BannerMessageType } from "../../Components/BannerMessage/BannerMessage";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";
import { CreateGuide, ListGuides } from "./guide.api";
import { GuideSummary, ListGuideResponse } from "./guide.models";
import { AppResponseWithContent } from "../../AppResponse";

import "./GuideTable.css";
import { CacheKeys } from "../../CacheKeys";
import { UserPermissionsEn } from "../user/user.models";
import { IoIosAddCircle } from "react-icons/io";
import { FadeLoader } from "react-spinners";

const GuideTable = () => {
	const [executorPermissions, setExeuctorPermissions] = useState<UserPermissionsEn[]>();
	const [guides, setGuides] = useState<GuideSummary[]>();
	const [searchTerm, setSearchTerm] = useState<string | null>(null);
	const [guideAddName, setGuideAddName] = useState<string | null>(null);
	const [guideAddContent, setGuideAddContent] = useState<string | null>(null);
	const [pageIndex, setPageIndex] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(10);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [failure, setFailure] = useState<string>("");
	const [toast, setToast] = useState<string>("");
	const [addModal, setAddModal] = useState<boolean>(false);
	const [addModalLoading, setAddModalLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (localStorage.getItem(CacheKeys.Permissions) !== null && localStorage.getItem(CacheKeys.Permissions) !== "") {
			let permissions: UserPermissionsEn[] = localStorage.getItem(CacheKeys.Permissions) === null ? {} : JSON.parse(localStorage.getItem(CacheKeys.Permissions)!);
			setExeuctorPermissions(permissions);
		}

		async function apiCall() {
			try {
				const appResponse: AppResponseWithContent<ListGuideResponse> = await ListGuides({
					searchTerm: searchTerm,
					pageIndex: pageIndex,
					pageSize: pageSize
				})

				if (appResponse.statusCode === 200) {
					setGuides(appResponse.content?.content)
					setTotalPages(appResponse.content?.totalPages ?? 1)
					setPageSize(appResponse.content?.pageSize ?? 10)
				}
				else {
					let errorMessage = "Unknown error occured.";

					if (appResponse.statusCode === 401) {
						errorMessage = "Failed to authenticate discord user. Please try again later."
					}

					setFailure(appResponse.message ?? errorMessage);
				}
			}
			catch (err) {
				setFailure("Unknown error occured.");
			}
		}

		apiCall();
	}, [setGuides, setTotalPages, setPageSize, pageIndex, searchTerm]);

	const onClick = (name: string) => navigate(`/guides/guide?guide=${encodeURIComponent(name).replace(/%20/g, "+")}`);

	const onPaginate = (direction: string) => {
		if (direction === "left") {
			if (pageIndex > 1) {
				setPageIndex(pageIndex - 1)
			}
		}

		if (direction === "right") {
			if (pageIndex < totalPages) {
				setPageIndex(pageIndex + 1)
			}
		}
	}

	const handleAdd = async () => {
		setAddModalLoading(true);

		try {
			const appResponse: AppResponseWithContent<number> = await CreateGuide({
				guideName: guideAddName ?? "",
				guideContent: guideAddContent,
				isVisible: true
			});

			setAddModal(false);
			setAddModalLoading(false);

			if (appResponse.statusCode !== 200) {
				setToast("Failed to create guide.");
			}
			else if (appResponse.statusCode === 200) {
				navigate(`guide?guide=${appResponse.content}`);
			}
		}
		catch (error) {
			setToast("Unknown error occured when creating guide.")
			setAddModal(false);
		}
	}

	return (
		<>
			{
				failure.length > 0 ?
					<BannerMessage closable={true} description={failure} title="Oh no!" type={BannerMessageType.Error} />
					:
					<></>
			}

			<h1>Guides</h1>

			{
				toast.length > 0 ?
					<BannerMessage closable={true} description={toast} title="Oh no!" type={BannerMessageType.Error} />
					:
					<></>
			}

			<div className="tableControls">
				<input className="searchInput" name="searchTerm" placeholder="e.g Getting Started" onChange={(input) => setSearchTerm(input.target.value)} />
				<div className="paginationButton" onClick={() => onPaginate("left")}>
					<BsCaretLeftFill />
				</div>

				<div className="paginationInfo">
					<p>{pageIndex}/{totalPages}</p>
				</div>

				<div className="paginationButton" onClick={() => onPaginate("right")}>
					<BsCaretRightFill />
				</div>

				{
					executorPermissions?.includes(UserPermissionsEn.CreateGuide) ?
						<div className="addButton" onClick={() => setAddModal(true)}>
							<IoIosAddCircle />
						</div>
						:
						<></>
				}
			</div>

			<table>
				<thead>
					<tr>
						<th>Name</th>
					</tr>
				</thead>
				<tbody>
					{guides?.map((guide) => (
						<tr className="table-item" key={guide.id} onClick={() => onClick(guide.name)}>
							<td>{guide.name}</td>
						</tr>
					))}
				</tbody>
			</table>

			{
				addModal ?
					<div id="modal" className="modal">
						<div className="modal-content">
							<h2>Create Guide</h2>

							{
								!addModalLoading ?
									<>
										<p className="modal-add-text">Name</p>
										<input className="guideAddNameInput" name="guideName" placeholder="Getting Started.." onChange={(input) => setGuideAddName(input.target.value)} />

										<p className="modal-add-text">Content</p>
										<textarea className="guideAddContentInput" name="guideContent" placeholder="# Getting Started Guide..." onChange={(input) => setGuideAddContent(input.target.value)} />

										<div className="modal-button modal-add" onClick={() => handleAdd()}>
											<p className="modal-button-text">Add</p>
										</div>

										<div className="modal-button modal-cancel-add" onClick={() => setAddModal(false)}>
											<p className="modal-button-text-add">Cancel</p>
										</div>
									</>
									:
									<>
										<FadeLoader margin="10" color="#0d47a1" />
									</>
							}
						</div>
					</div>
					:
					<></>
			}
		</>
	)
}

export default GuideTable;