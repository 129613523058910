
import BannerMessage, { BannerMessageType } from "../../Components/BannerMessage/BannerMessage";


import "./Home.css"

const Home = () => {


  return (
    <>
      <br />
      <BannerMessage closable={false} type={BannerMessageType.Info} title={"Play Now!"} description={"We have now opened our doors to the public! Join the fun at play.residualrp.com!"} />
      <br />
      <BannerMessage closable={true} type={BannerMessageType.Info} title={"Codecember!"} description={"We're feeling festive this December, so every single day we'll be dropping Christmas Bonus Codes that you can redeem in-game with /redeemcode. 🎅🎉 Join our discord at discord.gg/residualrp for more info!"} />
    </>
  );
}

export default Home;