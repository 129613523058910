import { useNavigate, useSearchParams } from "react-router-dom";
import BannerMessage, { BannerMessageType } from "../../Components/BannerMessage/BannerMessage";
import { ReactElement, useEffect, useState } from "react";
import { UserPermissionsEn } from "../user/user.models";
import { CacheKeys } from "../../CacheKeys";
import { AppResponse, AppResponseWithContent } from "../../AppResponse";
import { FadeLoader } from "react-spinners";
import { GuideDetails } from "./guide.models";
import { DeleteGuide, GetGuideById, UpdateGuide } from "./guide.api";
import { IoMdTrash } from "react-icons/io";
import { ImHammer2 } from "react-icons/im";

const ViewGuide = () => {
	const navigate = useNavigate();
	const [guide, setGuide] = useState<GuideDetails>();
	const [guideUpdateName, setGuideUpdateName] = useState<string>("");
	const [guideUpdateContent, setGuideUpdateContent] = useState<string>("");
	const [searchParams] = useSearchParams();
	const [failure, setFailure] = useState<string>("");
	const [toast, setToast] = useState<string>("");
	const [executorPermissions, setExeuctorPermissions] = useState<UserPermissionsEn[]>();
	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [updateModal, setUpdateModal] = useState<boolean>(false);
	const [updateLoading, setUpdateLoading] = useState<boolean>(false);
	const [contentComponents, setContentComponents] = useState<ReactElement[]>();
	const [updates, setUpdates] = useState<number>(0);

	useEffect(() => {
		const id = searchParams.get("guide") as string | number | null;

		if (id === null) {
			setFailure("Failed to fetch user data");
			return;
		}

		if (localStorage.getItem(CacheKeys.Permissions) !== null && localStorage.getItem(CacheKeys.Permissions) !== "") {
			let permissions: UserPermissionsEn[] = localStorage.getItem(CacheKeys.Permissions) === null ? {} : JSON.parse(localStorage.getItem(CacheKeys.Permissions)!);
			setExeuctorPermissions(permissions);
		}

		async function apiCall() {
			try {
				const appResponse: AppResponseWithContent<GuideDetails> = await GetGuideById({
					guideSearch: id!.toString()
				});

				if (appResponse.statusCode === 200) {
					setGuide(appResponse.content!);
					setGuideUpdateName(appResponse.content!.name);
					setGuideUpdateContent(appResponse.content!.content);

					const lines: string[] = appResponse.content?.content === undefined ? [] : appResponse.content?.content.split("\n");

					let content: ReactElement[] = [];

					for (let i = 0; i < lines.length; i++) {
						const line: string = lines[i];

						if (line.startsWith("# ")) {
							content.push(<h1 key={i}>{line.replace("# ", "")}</h1>)
						}
						else if (line.startsWith("## ")) {
							content.push(<h2 key={i}>{line.replace("## ", "")}</h2>)
						}
						else if (line.startsWith("### ")) {
							content.push(<h3 key={i}>{line.replace("### ", "")}</h3>)
						}
						else if (line.startsWith("#### ")) {
							content.push(<h4 key={i}>{line.replace("#### ", "")}</h4>)
						}
						else if (line.startsWith("##### ")) {
							content.push(<h5 key={i}>{line.replace("##### ", "")}</h5>)
						}
						else if (line.startsWith("###### ")) {
							content.push(<h6 key={i}>{line.replace("###### ", "")}</h6>)
						}
						else if (line.startsWith("- ")) {
							content.push(<p key={i}>&bull; {line.replace("- ", "")}</p>)
						}
						else if (line === "") {
							content.push(<br key={i} />)
							continue;
						}
						else {
							content.push(<p key={i}>{line}</p>)
						}
					}

					setContentComponents(content);
				}
				else {
					let errorMessage = "Unknown error occured.";

					if (appResponse.statusCode === 404) {
						errorMessage = `The User with the Id ${id} does not exist.`
					}

					setFailure(appResponse.message ?? errorMessage);
				}
			}
			catch (err) {
				setFailure("Unknown error occured.");
			}
		}

		apiCall();
	}, [setGuide, setContentComponents, setExeuctorPermissions, updates, searchParams, failure]);

	const handleUpdate = async () => {
		setUpdateLoading(true);

		try {
			const appResponse: AppResponse = await UpdateGuide({
				guideId: guide!.id,
				guideName: guideUpdateName ?? "",
				guideContent: guideUpdateContent,
			});

			setUpdateModal(false);
			setUpdateLoading(false);

			if (appResponse.statusCode !== 200) {
				setToast("Failed to update guide.");
			}
			else if (appResponse.statusCode === 200) {
				setUpdates(updates + 1);
			}
		}
		catch (error) {
			setToast("Unknown error occured when updating guide.")
			setUpdateModal(false);
		}
	}

	const handleDelete = async () => {
		setDeleteLoading(true);

		try {
			const appResponse: AppResponse = await DeleteGuide({
				guideId: guide!.id
			});

			setDeleteModal(false);
			setDeleteLoading(false);

			if (appResponse.statusCode !== 200) {
				setToast("Failed delete user.");
			}
			else if (appResponse.statusCode === 200) {
				navigate("/guides");
			}
		}
		catch (error) {
			setToast("Unknown error occured when deleting user.")
			setDeleteModal(false);
		}
	}

	return (
		<>
			<h1>{guide?.name}</h1>

			{
				toast.length > 0 ?
					<BannerMessage closable={true} description={toast} title="Oh no!" type={BannerMessageType.Error} />
					:
					<></>
			}

			{
				failure.length > 0 ?
					<BannerMessage closable={true} description={failure} title="Oh no!" type={BannerMessageType.Error} />
					:
					<div className="uneditableInfo">
						<div className="actionContainer">
							{
								executorPermissions?.includes(UserPermissionsEn.UpdateGuide) ?
									<div className="actionButton saveButton" onClick={() => setUpdateModal(true)}>
										<ImHammer2 className="saveIcon" />
										<p className="saveText">Edit</p>
									</div>
									:
									<></>
							}
							{
								executorPermissions?.includes(UserPermissionsEn.DeleteGuide) ?
									<div className="actionButton suspendButton" onClick={() => setDeleteModal(true)}>
										<IoMdTrash className="saveIcon" />
										<p className="saveText">Delete</p>
									</div>
									:
									<></>
							}
						</div>

						{contentComponents}
					</div>
			}

			{
				deleteModal ?
					<div id="modal" className="modal">
						<div className="modal-content">
							<h2>Are you sure?</h2>
							<p>This action cannot be undone.</p>
							{
								!deleteLoading ?
									<>
										<div className="modal-button modal-ok" onClick={() => handleDelete()}>
											<p className="modal-button-text">Permanently Delete</p>
										</div>
										<div className="modal-button modal-cancel" onClick={() => setDeleteModal(false)}>
											<p className="modal-button-text">Cancel</p>
										</div>
									</>
									:
									<>
										<FadeLoader margin="10" color="#0d47a1" />
									</>
							}
						</div>
					</div>
					:
					<></>
			}

			{
				updateModal ?
					<div id="modal" className="modal">
						<div className="modal-content">
							<h2>Update Guide</h2>

							{
								!updateLoading ?
									<>
										<p className="modal-add-text">Name</p>
										<input className="guideAddNameInput" name="guideName" value={guideUpdateName!} placeholder="Getting Started.." onChange={(input) => setGuideUpdateName(input.target.value)} />

										<p className="modal-add-text">Content</p>
										<textarea className="guideAddContentInput" name="guideContent" value={guideUpdateContent!} placeholder="# Getting Started Guide..." onChange={(input) => setGuideUpdateContent(input.target.value)} />

										<div className="modal-button modal-add" onClick={() => handleUpdate()}>
											<p className="modal-button-text">Update</p>
										</div>

										<div className="modal-button modal-cancel-add" onClick={() => setUpdateModal(false)}>
											<p className="modal-button-text-add">Cancel</p>
										</div>
									</>
									:
									<>
										<FadeLoader margin="10" color="#0d47a1" />
									</>
							}
						</div>
					</div>
					:
					<></>
			}
		</>
	);
}

export default ViewGuide;