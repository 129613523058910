import { useNavigate, useSearchParams } from "react-router-dom";
import "./Login.css"
import { useEffect, useState } from "react";
import { AppResponse } from "../../AppResponse";
import { GetLogoutUser } from "./user.api";
import { CacheKeys } from "../../CacheKeys";
import BannerMessage, { BannerMessageType } from "../../Components/BannerMessage/BannerMessage";

const Logout = () => {
	const navigate = useNavigate();
	const [failure, setFailure] = useState<string>("");

	useEffect(() => {
		async function apiCall() {
			try {
				const appResponse: AppResponse = await GetLogoutUser();

				if (appResponse.statusCode === 200) {
					localStorage.removeItem(CacheKeys.AuthToken);
					localStorage.removeItem(CacheKeys.Permissions);
					navigate("/");
				}
				else {
					let errorMessage = "Unknown error occured.";

					setFailure(appResponse.message ?? errorMessage);
				}
			}
			catch (err) {
				setFailure("Unknown error occured.");
			}
		}

		apiCall();
	}, []);

	return (
		<>
			<h1>Loggout out...</h1>
			<p>Please wait...</p>
			{
				failure.length > 0 ?
					<BannerMessage closable={true} description={failure} title="Oh no!" type={BannerMessageType.Error} />
					:
					<></>
			}
		</>
	);
}

export default Logout;