import { AppResponse, AppResponseWithContent } from "../../AppResponse";
import { GetUserRequest, UserDetails, ListUserResponse, ListUsersRequest, LoginUserRequest, VerifyUserResponse, AllowedActionsResponse, UpdateUserPermissionsRequest, SuspendUserRequest, DeleteUserRequest } from "./user.models";

import Config from "../../config.json";
import { CacheKeys } from "../../CacheKeys";

async function PostVerifyUser(request: LoginUserRequest): Promise<AppResponseWithContent<VerifyUserResponse>> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/auth/verify-user`, {
			method: "POST",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(request),
		});

		if (!await response.ok) {
			return {
				content: null,
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			content: null,
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

async function GetLogoutUser(): Promise<AppResponse> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/auth/logout`, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			},
		});

		if (!await response.ok) {
			return {
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

async function ListUsers(request: ListUsersRequest): Promise<AppResponseWithContent<ListUserResponse>> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/auth?searchTerm=${request.searchTerm ?? ""}&isSuspended=${request.isSuspended ?? ""}&pageSize=${request.pageSize}&pageIndex=${request.pageIndex}`, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			},
		});

		if (!await response.ok) {
			return {
				content: null,
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			content: null,
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

async function GetUser(request: GetUserRequest): Promise<AppResponseWithContent<UserDetails>> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/auth/${request.id}`, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			},
		});

		if (!await response.ok) {
			return {
				content: null,
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			content: null,
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

async function GetAllowedActions(request: GetUserRequest): Promise<AppResponseWithContent<AllowedActionsResponse>> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/auth/${request.id}/list-valid-actions`, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			},
		});

		if (!await response.ok) {
			return {
				content: null,
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			content: null,
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

async function PutUpdateUserPermissions(request: UpdateUserPermissionsRequest): Promise<AppResponse> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/auth/${request.id}/permissions`, {
			method: "PUT",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			},
			body: JSON.stringify(request),
		});

		if (!await response.ok) {
			return {
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

async function PutSuspendUser(request: SuspendUserRequest): Promise<AppResponse> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/auth/${request.id}/suspend`, {
			method: "PUT",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			}
		});

		if (!await response.ok) {
			return {
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

async function DeleteUser(request: DeleteUserRequest): Promise<AppResponse> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/auth/${request.id}`, {
			method: "DELETE",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			}
		});

		if (!await response.ok) {
			return {
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

export {
	PostVerifyUser,
	GetLogoutUser,
	ListUsers,
	GetUser,
	GetAllowedActions,
	PutUpdateUserPermissions,
	PutSuspendUser,
	DeleteUser
}