import { AppResponseWithContent } from "../../AppResponse";
import { CacheKeys } from "../../CacheKeys";
import Config from "../../config.json";
import { DiscordRoleDto } from "./roles.models";

async function GetRoles(): Promise<AppResponseWithContent<DiscordRoleDto[]>> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/roles`, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			},
		});

		if (!await response.ok) {
			return {
				content: null,
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			content: null,
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

export {
	GetRoles
}