import { Outlet, Link } from "react-router-dom";

import logo from "./static/residual_logo.png"
import SideNavigation from "./Components/SideNavigation/SideNavigation";

const Layout = () => {
  return (
    <div className="main-page">
      <header>
        <div className="header-img-container">
          <img className="header-img" src={logo} alt="Residual RP logo" />
        </div>
        <nav className="nav-container">
          <ul className="nav-list">
            <li className="nav-list-item">
              <Link to="fivem://connect/play.residualrp.com:30120">Play</Link>
            </li>
            <li className="nav-list-item">
              <Link to="/rules">Rules</Link>
            </li>
            <li className="nav-list-item">
              <Link to="/legal/privacypolicy">Privacy Policy</Link>
            </li>
            <li className="nav-list-item">
              <Link to="/legal/termsandconditions">Terms and Conditions</Link>
            </li>
            <li className="nav-list-item">
              <Link to="https://discord.gg/residualrp">Discord</Link>
            </li>
          </ul>
        </nav>
      </header>

      <div className="main-content-view">
        <SideNavigation />
        <div className="main-outlet">
          <Outlet />
        </div>
      </div>
    </div>
  )
};

export default Layout;