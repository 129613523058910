import { AppResponse, AppResponseWithContent } from "../../AppResponse";
import { CreateGuideRequest, CreateGuideResponse, DeleteGuideRequest, GetGuideRequest, GuideDetails, ListGuideRequest, ListGuideResponse, UpdateGuideRequest } from "./guide.models";

import Config from "../../config.json";
import { CacheKeys } from "../../CacheKeys";

async function ListGuides(request: ListGuideRequest): Promise<AppResponseWithContent<ListGuideResponse>> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/guides?searchTerm=${request.searchTerm ?? ""}&pageSize=${request.pageSize}&pageIndex=${request.pageIndex}`, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			},
		});

		if (!await response.ok) {
			return {
				content: null,
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			content: null,
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

async function GetGuideById(request: GetGuideRequest): Promise<AppResponseWithContent<GuideDetails>> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/guides/${request.guideSearch}`, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			},
		});

		if (!await response.ok) {
			return {
				content: null,
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			content: null,
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

async function CreateGuide(request: CreateGuideRequest): Promise<AppResponseWithContent<number>> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/guides`, {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			},
			body: JSON.stringify(request)
		});

		if (!await response.ok) {
			return {
				content: null,
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			content: null,
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

async function UpdateGuide(request: UpdateGuideRequest): Promise<AppResponse> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/guides/${request.guideId}`, {
			method: "PUT",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			},
			body: JSON.stringify(request)
		});

		if (!await response.ok) {
			return {
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

async function DeleteGuide(request: DeleteGuideRequest): Promise<AppResponse> {
	try {
		const response = await fetch(`${Config.apiBaseUrl}/api/guides/${request.guideId}`, {
			method: "DELETE",
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${localStorage.getItem(CacheKeys.AuthToken)}`
			}
		});

		if (!await response.ok) {
			return {
				message: null,
				statusCode: await response.status
			}
		}

		return await response.json();
	} catch (error) {
		return {
			message: "Unknown Error",
			statusCode: 500
		}
	}
}

export {
	ListGuides,
	CreateGuide,
	GetGuideById,
	UpdateGuide,
	DeleteGuide
}