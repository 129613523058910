export type UserSummary = {
	id: number,
	name: string,
	discordId: string,
	isSuspended: boolean
}

export enum UserPermissionsEn {
	// Users
	ReadUsers = 1,
	SuspendUsers = 2,
	DeleteUsers = 3,

	// Police Tree Assign
	AssignPoliceBand5 = 21,
	AssignPoliceBand4 = 22,
	AssignPoliceBand3 = 23,
	AssignPoliceBand2 = 24,
	AssignPoliceBand1 = 25,
	AssignPoliceNonBanded = 26,

	// RAS Tree Assign
	AssignRasBand5 = 31,
	AssignRasBand4 = 32,
	AssignRasBand3 = 33,
	AssignRasBand2 = 34,
	AssignRasBand1 = 35,
	AssignRasNonBanded = 36,


	// College Tree Assign
	AssignTrainingBand5 = 41,
	AssignTrainingBand4 = 42,
	AssignTrainingBand3 = 43,
	AssignTrainingBand2 = 44,
	AssignTrainingBand1 = 45,

	// Guides
	CreateGuide = 51,
	UpdateGuide = 52,
	ToggleGuide = 53,
	DeleteGuide = 54,

	Developer = 999,
	Admin = 998,
	Moderator = 997,
}

export type UserDetails = {
	id: number,
	name: string,
	discordId: string,
	isSuspended: boolean,
	roles: string[],
	permissions: UserPermissionsEn[],
	lastLoginAt: Date | null,
	isActive: boolean
}

export type LoginUserRequest = {
	Code: string
}

export type VerifyUserResponse = {
	accessToken: string,
	expiresIn: number,
	roles: string[],
	permissions: number[]
}

export type ListUsersRequest = {
	searchTerm?: string | null,
	isSuspended?: boolean | null,
	pageSize: number,
	pageIndex: number
}

export type ListUserResponse = {
	content: UserSummary[],
	pageIndex: number,
	pageSize: number,
	totalPages: number
}

export type GetUserRequest = {
	id: number
}

export type AllowedActionsResponse = {
	roles: string[],
	permissions: UserPermissionsEn[]
}

export type UpdateUserPermissionsRequest = {
	id: number,
	roles: string[],
	permissions: UserPermissionsEn[]
}

export type SuspendUserRequest = {
	id: number,
}

export type DeleteUserRequest = {
	id: number,
}