import "./Legal.css"

const PrivacyPolicy = () => {
	return (
		<div className="legal-container">
			<h1>Privacy Policy</h1>

			<ol className="legal-list">
				<li>
					<p className="legal-title">Introduction</p>
					<p className="legal-description">
						Welcome to the privacy policy of Residual Developments LTD (“we,” “us,” or “our”). We are committed to safeguarding your privacy and ensuring that your personal data is processed in compliance with applicable laws, including the UK General Data Protection Regulation (GDPR). This policy outlines how we collect, use, disclose, and protect your information when you visit our websites: <a href="https://www.residualrp.com">www.residualrp.com</a>, <a href="https://lms.residualrp.com">lms.residualrp.com</a>, and <a href="https://dashboard.residualrp.com">dashboard.residualrp.com</a> (collectively, the “Websites”), and when you engage with our online gaming platform and services.
					</p>
					<p className="legal-description">
						By using our Websites and services, you consent to the collection and use of your personal data as described in this policy. If you do not agree with the terms of this policy, please do not use our Websites or services.
					</p>
				</li>

				<li>
					<p className="legal-title">Personal Data We Collect</p>
					<p className="legal-description">
						We may collect and process various types of personal data about you, including but not limited to:
					</p>
					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								<strong>Discord Data</strong>: When you join our server or create a support ticket, we collect personal information necessary for logging purposes, which may include your Discord username and Discord identifier and any other relevant details required for auditing.
							</p>
						</li>

						<li>
							<p className="legal-description">
								<strong>FiveM Account Data</strong>: We gather information related to your FiveM account, which may include in-game identifiers, Steam ID and your IP address.
							</p>
						</li>

						<li>
							<p className="legal-description">
								<strong>Technical Data</strong>: We may collect data regarding your device, internet connection, browser type, operating system, IP address, and other similar information to facilitate your access to our Websites and improve their functionality.
							</p>
						</li>

						<li>
							<p className="legal-description">
								<strong>Usage Data</strong>: We collect information about how you use our Websites and services, including your interaction with content, pages visited, and time spent on specific sections, to analyze usage patterns and improve our offerings.
							</p>
						</li>

						<li>
							<p className="legal-description">
								<strong>Support Ticket Data</strong>: Any data related to support tickets created via Discord, including messages, timestamps, and resolution status.
							</p>
						</li>

						<li>
							<p className="legal-description">
								<strong>Communications Data</strong>: Any communications you send to us, including support inquiries, feedback, and other correspondence, may be collected and stored.
							</p>
						</li>
					</ul>
				</li>

				<li>
					<p className="legal-title">Purposes of Processing and Legal Bases</p>
					<p className="legal-description">
						We process your personal data for various legitimate purposes, including:
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								<strong>Business Operations</strong>: We may process your personal data for the purposes of operating our Websites, ensuring their functionality, and providing our services effectively. This includes maintaining website performance, implementing updates, and troubleshooting issues. This processing is necessary for our legitimate interests in properly administering our Websites and services, ensuring that you can access and enjoy the gaming experience we offer.
							</p>
						</li>

						<li>
							<p className="legal-description">
								<strong>Marketing</strong>: We may process account data to create, target, and send direct marketing communications, including promotional emails about our games, updates, and special offers. We will only send marketing communications where you have opted in to receive them. We seek your consent to process your data for direct marketing purposes, and you have the right to withdraw this consent at any time.
							</p>
						</li>

						<li>
							<p className="legal-description">
								<strong>Auditing</strong>: We may process your personal data to create and maintain databases, including backups, records of transactions, and logs of user activities. This ensures we have access to all necessary information for efficient service delivery and compliance with legal obligations. Our legitimate interests in maintaining accurate records and ensuring operational efficiency support this processing.
							</p>
						</li>

						<li>
							<p className="legal-description">
								<strong>Legal Compliance</strong>: We process your personal data to comply with applicable laws, regulations, and legal obligations. This includes fulfilling tax obligations, responding to lawful requests from regulatory authorities, and ensuring adherence to data protection laws. We are required to process your data to comply with legal obligations imposed on us, and this processing is essential to maintain our operational integrity.
							</p>
						</li>

						<li>
							<p className="legal-description">
								<strong>Security Measures</strong>: To protect our services from fraud and unauthorized access, we process your personal data to implement security measures. This includes monitoring user activity for suspicious behavior, preventing unauthorized transactions, and ensuring that our systems are secure. Our legitimate interests in safeguarding our services and user data justify this processing, as it is essential for maintaining trust and safety within our gaming community.
							</p>
						</li>
					</ul>
				</li>

				<li>
					<p className="legal-title">Providing Your Personal Data to Others</p>
					<p className="legal-description">
						We value your privacy and do not sell or rent your personal data to third parties. However, we may share your personal data with:
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								<strong>Service Providers</strong>: Trusted third-party vendors and service providers who assist us in operating our Websites, providing our services, or conducting our business activities. These parties are required to maintain the confidentiality of your personal data and process it only on our behalf and according to our instructions.
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>Legal Authorities</strong>: We may disclose your personal data if required by law, in response to a subpoena, or to protect our rights, privacy, safety, or property, or that of others.
							</p>
						</li>
					</ul>
				</li>

				<li>
					<p className="legal-title">International Transfers of Your Personal Data</p>
					<p className="legal-description">
						Your personal data may be transferred to, and processed in, countries outside the UK and the European Economic Area (EEA). If we transfer your personal data outside of the EEA, we will ensure that appropriate safeguards are in place in accordance with GDPR, such as using Standard Contractual Clauses or ensuring the recipient country has an adequate level of data protection.
					</p>
				</li>

				<li>
					<p className="legal-title">Retaining and Deleting Personal Data</p>
					<p className="legal-description">
						We will retain your personal data only for as long as necessary to fulfil the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements.
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								<strong>Support Ticket Data</strong>: All data related to support tickets created via Discord will be retained for a period of 12 months. After this period, the data will automatically be removed through our retention policy, ensuring compliance with data minimization principles.
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>Other Personal Data</strong>: Personal data processed for any purpose shall not be kept longer than necessary. When your personal data is no longer necessary, we will securely delete it or anonymize it. Specific retention periods include:

								<ul className="legal-sub-list">
									<li>
										<p className="legal-description">
											<strong>Account Data</strong>: Retained for the duration of your account's existence and for a period thereafter as required by law.
										</p>
									</li>
									<li>
										<p className="legal-description">
											<strong>Usage Data</strong>: Retained for analysis purposes for a limited time, after which it may be anonymized.
										</p>
									</li>
									<li>
										<p className="legal-description">
											<strong>Communications Data</strong>: Retained for a period necessary to address your inquiries and provide support.
										</p>
									</li>
								</ul>
							</p>
						</li>
					</ul>
				</li>

				<li>
					<p className="legal-title">Your Rights Under GDPR</p>
					<p className="legal-description">
						As a data subject, you have the following rights regarding your personal data under GDPR:
					</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								<strong>Right to Access</strong>: You have the right to request a copy of the personal data we hold about you and details about how we process it.
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>Right to Rectification</strong>: You have the right to request correction of any inaccurate or incomplete personal data we hold about you.
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>Right to Erasure</strong>: You have the right to request deletion of your personal data under certain circumstances, such as when it is no longer necessary for the purposes for which it was collected.
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>Right to Restrict Processing</strong>: You have the right to request the restriction of processing your personal data when certain conditions are met.
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>Right to Data Portability</strong>: You have the right to receive your personal data in a structured, commonly used, and machine-readable format, and to request the transfer of that data to another controller.
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>Right to Object</strong>: You have the right to object to the processing of your personal data in certain situations, particularly when it is based on legitimate interests or for direct marketing purposes.
							</p>
						</li>
					</ul>

					<p className="legal-description">
						To exercise any of these rights, please contact us using the contact details provided in this policy. We may ask you to verify your identity before responding to such requests.
					</p>
				</li>

				<li>
					<p className="legal-title">Cookies and Tracking Technologies</p>
					<p className="legal-description">
						We use cookies and similar tracking technologies to enhance your experience on our Websites. Cookies are small text files stored on your device that help us understand how you use our Websites and improve our services. You can manage your cookie preferences through your browser settings. For more information about our use of cookies, please refer to our Cookie Policy.
					</p>
				</li>

				<li>
					<p className="legal-title">Security of Your Personal Data</p>
					<p className="legal-description">
						We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, loss, destruction, or alteration. These measures include encryption, access controls, and regular security assessments. However, please be aware that no method of transmission over the internet or method of electronic storage is 100% secure.
					</p>
				</li>

				<li>
					<p className="legal-title">Changes to This Privacy Policy</p>
					<p className="legal-description">
						We may update this privacy policy from time to time. Any changes we make will be effective immediately upon posting the revised policy on our Websites. We will notify you of any significant changes by email or through a notice on our Discord. Please check back frequently to see any updates or changes to this privacy policy.
					</p>
				</li>

				<li>
					<p className="legal-title">Contact Us</p>
					<p className="legal-description">
						If you have any questions, comments, or concerns about this privacy policy or our privacy practices, please contact us at:
					</p>
					<p>
						<strong>Residual Developments LTD</strong>
					</p>
					<p>
						<a href="mailto:compliance@residualrp.com">compliance@residualrp.com</a>
					</p>
					<p>
						71-75 Shelton Street, Convent Garden, London, United Kingdom, WC2H 9JQ
					</p>
				</li>

				<li>
					<p className="legal-title">Complaints</p>
					<p className="legal-description">
						If you believe that we have not complied with your data protection rights, you have the right to lodge a complaint with the Information Commissioner's Office (ICO) in the UK, the supervisory authority for data protection issues.
					</p>
				</li>

			</ol>
		</div>
	)
}

export default PrivacyPolicy;