import { useNavigate, useSearchParams } from "react-router-dom";
import "./Login.css"
import { useEffect, useState } from "react";
import { AppResponseWithContent } from "../../AppResponse";
import { VerifyUserResponse } from "./user.models";
import { PostVerifyUser } from "./user.api";
import { CacheKeys } from "../../CacheKeys";
import BannerMessage, { BannerMessageType } from "../../Components/BannerMessage/BannerMessage";

const Login = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [failure, setFailure] = useState<string>("");

	useEffect(() => {
		const code = searchParams.get("code") as string | null;

		if (code === null) {
			navigate("/");
			return;
		}

		if (code.trim() === "") {
			navigate("/");
			return;
		}

		async function apiCall() {
			try {
				const appResponse: AppResponseWithContent<VerifyUserResponse> = await PostVerifyUser({
					Code: code!
				})

				if (appResponse.statusCode === 200) {
					localStorage.setItem(CacheKeys.AuthToken, appResponse.content!.accessToken)
					localStorage.setItem(CacheKeys.Permissions, JSON.stringify(appResponse.content!.permissions))
					navigate("/");
				}
				else {
					let errorMessage = "Unknown error occured.";

					if (appResponse.statusCode === 401) {
						errorMessage = "Failed to authenticate discord user. Please try again later."
					}

					setFailure(appResponse.message ?? errorMessage);
				}
			}
			catch (err) {
				setFailure("Unknown error occured.");
			}
		}

		apiCall();
	}, []);

	return (
		<>
			<h1>Authenticating User...</h1>
			<p>Please wait...</p>
			{
				failure.length > 0 ?
					<BannerMessage closable={true} description={failure} title="Oh no!" type={BannerMessageType.Error} />
					:
					<></>
			}
		</>
	);
}

export default Login;