import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import BannerMessage, { BannerMessageType } from "../../Components/BannerMessage/BannerMessage";
import { DeleteUser, GetAllowedActions, GetUser, PutSuspendUser, PutUpdateUserPermissions } from "./user.api";
import { AllowedActionsResponse, UserDetails, UserPermissionsEn } from "./user.models";
import { AppResponse, AppResponseWithContent } from "../../AppResponse";
import { IoIosSave, IoMdCheckmarkCircle, IoMdTrash } from "react-icons/io";
import { ImHammer2 } from "react-icons/im";

import "./ViewUser.css"
import PermissionCheckBox from "../../Components/PermissionCheckBox/PermissionCheckBox";
import { DiscordRoleDto } from "../roles/roles.models";
import { GetRoles } from "../roles/role.api";
import { CacheKeys } from "../../CacheKeys";
import { FadeLoader } from "react-spinners";

const ViewUser = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [failure, setFailure] = useState<string>("");
	const [toast, setToast] = useState<string>("");
	const [user, setUser] = useState<UserDetails>();
	const [allowedActions, setAllowedActions] = useState<AllowedActionsResponse>();
	const [roles, setRoles] = useState<DiscordRoleDto[]>();
	const [draftRoles, setDraftRoles] = useState<string[]>();
	const [draftPermissions, setDraftPermissions] = useState<UserPermissionsEn[]>();
	const [saveLoading, setSaveLoading] = useState<boolean>(false);
	const [suspendLoading, setSuspendLoading] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [executorPermissions, setExeuctorPermissions] = useState<UserPermissionsEn[]>();
	const [deleteModal, setDeleteModal] = useState<boolean>(false);

	useEffect(() => {
		const id = searchParams.get("userId") as string | null;

		if (id === null) {
			setFailure("Failed to fetch user data");
			return;
		}

		if (id.trim() === "") {
			setFailure("Failed to fetch user data");
			return;
		}

		if (localStorage.getItem(CacheKeys.Permissions) !== null && localStorage.getItem(CacheKeys.Permissions) !== "") {
			let permissions: UserPermissionsEn[] = localStorage.getItem(CacheKeys.Permissions) === null ? {} : JSON.parse(localStorage.getItem(CacheKeys.Permissions)!);
			setExeuctorPermissions(permissions);
		}

		async function apiCall() {
			try {
				const rolesAppResponse: AppResponseWithContent<DiscordRoleDto[]> = await GetRoles();

				if (rolesAppResponse.statusCode === 200) {
					setRoles(rolesAppResponse.content!);
				}
				else {
					setFailure("Failed to fetch discord roles.");
				}

				const appResponse: AppResponseWithContent<UserDetails> = await GetUser({
					id: parseInt(id!)
				});

				if (appResponse.statusCode === 200) {
					setUser(appResponse.content!)
					setDraftRoles(appResponse.content!.roles);
					setDraftPermissions(appResponse.content!.permissions);
				}
				else {
					let errorMessage = "Unknown error occured.";

					if (appResponse.statusCode === 404) {
						errorMessage = `The User with the Id ${id} does not exist.`
					}

					setFailure(appResponse.message ?? errorMessage);
				}

				const allowedAppResponse: AppResponseWithContent<AllowedActionsResponse> = await GetAllowedActions({
					id: parseInt(id!)
				});

				if (allowedAppResponse.statusCode === 200) {
					setAllowedActions(allowedAppResponse.content!)
				}
				else {
					let errorMessage = "Unknown error occured";

					if (allowedAppResponse.statusCode === 400) {
						errorMessage = `You cannot modify your own permissions.`
					}

					setFailure(failure + `\n${errorMessage}`);
				}
			}
			catch (err) {
				setFailure("Unknown error occured.");
			}
		}

		apiCall();
	}, [setUser, setExeuctorPermissions, searchParams, failure, saveLoading, suspendLoading]);

	const roleChangeCallback = (checked: boolean, id: string | number) => {
		let draft: string[] = draftRoles!;

		if (checked) {
			draft.push(`${id}`);
		}
		else {
			draft = draft.filter(x => x !== id);
		}

		setDraftRoles(draft);
	}

	const permissionChangeCallback = (checked: boolean, id: string | number) => {
		let draft: UserPermissionsEn[] = draftPermissions!;

		if (checked) {
			draft.push(id as number);
		}
		else {
			draft = draft.filter(x => x !== id);
		}

		setDraftPermissions(draft);
	}

	const getRoleNameFromId = (id: string): string => {
		for (let i = 0; i < roles?.length!; i++) {
			if (roles![i].discordRoleId === id) {
				return roles![i].name;
			}
		}

		return id;
	}

	const handlePermissionSaving = async () => {
		setSaveLoading(true);

		try {
			const appResponse: AppResponse = await PutUpdateUserPermissions({
				id: user!.id,
				roles: draftRoles!,
				permissions: draftPermissions!
			});

			if (appResponse.statusCode !== 200) {
				setToast("Failed save user's roles.");
			}

			setSaveLoading(false);
		}
		catch (error) {
			setToast("Unknown error occured when saving user's roles.")
		}
	}

	const handleSuspend = async () => {
		setSuspendLoading(true);

		try {
			const appResponse: AppResponse = await PutSuspendUser({
				id: user!.id
			});

			if (appResponse.statusCode !== 200) {
				setToast("Failed suspend user.");
			}

			setSuspendLoading(false);
		}
		catch (error) {
			setToast("Unknown error occured when suspending user.")
		}
	}

	const handleDelete = async () => {
		setDeleteLoading(true);

		try {
			const appResponse: AppResponse = await DeleteUser({
				id: user!.id
			});

			setDeleteModal(false);
			setDeleteLoading(false);

			if (appResponse.statusCode !== 200) {
				setToast("Failed delete user.");
			}
			else if (appResponse.statusCode === 200) {
				navigate("/users");
			}
		}
		catch (error) {
			setToast("Unknown error occured when deleting user.")
			setDeleteModal(false);
		}
	}

	return (
		<>
			<h1>User Profile</h1>

			{
				toast.length > 0 ?
					<BannerMessage closable={true} description={toast} title="Oh no!" type={BannerMessageType.Error} />
					:
					<></>
			}

			{
				failure.length > 0 ?
					<BannerMessage closable={true} description={failure} title="Oh no!" type={BannerMessageType.Error} />
					:
					<div className="uneditableInfo">
						<div className="info">
							<p className="infoLabel">Name:</p>
							<input className="inputBox" disabled={true} value={user?.name} />
						</div>

						<div className="info">
							<p className="infoLabel">Discord Id:</p>
							<input className="inputBox" disabled={true} value={user?.discordId} />
						</div>

						<div className="info">
							<p className="infoLabel">Active:</p>
							<p className="infoLabel">{user?.isActive ? "Online" : "Offline"}</p>
							{
								user?.isActive ?
									<>
										<p className="infoLabel">Last Logon:</p>
										<input className="inputBox" disabled={true} value={user?.lastLoginAt?.toString() ?? ""} />
									</>
									:
									<></>
							}
						</div>

						<div className="permissionContainer">
							<div className="permissionTree">
								<p className="permissionTreeHeader">Roles</p>
								{allowedActions?.roles.map(x =>
									<PermissionCheckBox
										callback={roleChangeCallback}
										label={getRoleNameFromId(x)}
										key={x}
										id={x}
										checked={user?.roles.includes(x) ?? false} />
								)}
							</div>
							<div className="permissionTree">
								<p className="permissionTreeHeader">Permissions</p>
								{allowedActions?.permissions.map(x =>
									<PermissionCheckBox
										callback={permissionChangeCallback}
										label={UserPermissionsEn[x]}
										key={x}
										id={x}
										checked={user?.permissions.includes(x) ?? false} />
								)}
							</div>
						</div>
						<div className="actionContainer">
							<div className="actionButton saveButton" onClick={() => handlePermissionSaving()}>
								{
									saveLoading ?
										<IoMdCheckmarkCircle className="saveIcon" />
										:
										<IoIosSave className="saveIcon" />
								}
								<p className="saveText">Save</p>
							</div>
							{
								executorPermissions?.includes(UserPermissionsEn.SuspendUsers) ?
									<div className="actionButton suspendButton" onClick={() => handleSuspend()}>
										{
											suspendLoading ?
												<IoMdCheckmarkCircle className="saveIcon" />
												:
												<ImHammer2 className="saveIcon" />
										}
										<p className="saveText">{user?.isSuspended ? "Unsuspend" : "Suspend"}</p>
									</div>
									:
									<></>
							}
							{
								executorPermissions?.includes(UserPermissionsEn.DeleteUsers) ?
									<div className="actionButton suspendButton" onClick={() => setDeleteModal(true)}>
										{
											deleteLoading ?
												<IoMdCheckmarkCircle className="saveIcon" />
												:
												<IoMdTrash className="saveIcon" />
										}
										<p className="saveText">Delete</p>
									</div>
									:
									<></>
							}
						</div>
					</div>
			}

			{
				deleteModal ?
					<div id="modal" className="modal">
						<div className="modal-content">
							<h2>Are you sure?</h2>
							<p>This action cannot be undone.</p>
							<p>Users must leave discord otherwise their account will remain on the system.</p>
							{
								!deleteLoading ?
									<>
										<div className="modal-button modal-ok" onClick={() => handleDelete()}>
											<p className="modal-button-text">Permanently Delete</p>
										</div>
										<div className="modal-button modal-cancel" onClick={() => setDeleteModal(false)}>
											<p className="modal-button-text">Cancel</p>
										</div>
									</>
									:
									<>
										<FadeLoader margin="10" color="#0d47a1" />
									</>
							}
						</div>
					</div>
					:
					<></>
			}
		</>
	);
}

export default ViewUser;