import "./Legal.css"

const Rules = () => {
	return (
		<div className="legal-container">
			<h1>Rules</h1>

			<h2>General Server Rules</h2>

			<ol className="legal-list">
				<li>
					<p className="legal-title">Respect for All Players</p>
					<p className="legal-description">
						Every player is expected to treat others with respect and courtesy. Discrimination, racism, harassment, and any form of offensive behaviour are strictly prohibited and will not be tolerated. Our community thrives on mutual respect, and any violation of this rule will lead to immediate consequences.
					</p>
				</li>
				<li>
					<p className="legal-title">Game Mechanics</p>
					<p className="legal-description">
						The use of cheats, hacks, glitches, exploits, or any third-party software that gives players an unfair advantage over others is strictly prohibited. Players found exploiting or manipulating game mechanics to gain an advantage will be subject to permanent bans.
					</p>
				</li>
				<li>
					<p className="legal-title">Advertising and Promotions</p>
					<p className="legal-description">
						Advertising other FiveM communities, Discord servers, website links, or external services within our community is not allowed. This includes using in-game chat (OCC or local chat), player names, or any other means to advertise external links or services.
					</p>
				</li>
				<li>
					<p className="legal-title">Leaking Personal Information (Doxing)</p>
					<p className="legal-description">
						Any attempt to share, expose, or gather personal information without the full consent of the individual involved is strictly forbidden. This includes doxing, hacking, or any form of privacy invasion. Breaches of privacy will result in a permanent ban from the server.
					</p>
				</li>
				<li>
					<p className="legal-title">Staff Decisions</p>
					<p className="legal-description">
						Staff decisions are final. If you disagree with any staff action, you may use our formal appeal process to challenge bans or decisions. However, staff have the final say, and any refusal to comply with their decision may result in further action.
					</p>
				</li>
				<li>
					<p className="legal-title">Competing Interests</p>
					<p className="legal-description">
						Players holding certain roles within Residual RP are not allowed to hold positions of influence in other British-themed FiveM servers. This policy ensures there is no conflict of interest that may compromise the integrity of Residual RP. Full details of this rule can be found in our Community Guidelines.
					</p>
				</li>
			</ol>

			<h2>Game Rules</h2>

			<ol className="legal-list">
				<li>
					<p className="legal-title">Language Standards</p>
					<p className="legal-description">
						All players must be proficient in English to a good standard in order to participate on our server. Effective communication is crucial for enjoyable roleplay.
					</p>
				</li>
				<li>
					<p className="legal-title">Griefing</p>
					<p className="legal-description">
						Any form of griefing, including but not limited to trolling, harassment, or disrupting the roleplay environment, will result in permanent removal from the server. This includes actions that negatively impact the experience of other players.
					</p>
				</li>
				<li>
					<p className="legal-title">Away From Keyboard (AFK)</p>
					<p className="legal-description">
						Players should not go AFK in-game unless necessary. If you are caught AFK during a critical situation or are targeted while AFK, you assume full responsibility for the consequences. Additionally, players are not allowed to target others for being AFK in a malicious way.
					</p>
				</li>
				<li>
					<p className="legal-title">Emergency Service Characters</p>
					<p className="legal-description">
						You are allowed to have only one emergency service character at a time, which can be switched between roles such as RAS or Police. These characters are not allowed to engage in any form of illegal activity, and doing so will result in removal from that emergency service.
					</p>
				</li>
				<li>
					<p className="legal-title">Character Name Requirements</p>
					<p className="legal-description">
						Your character's name must follow realistic conventions, consisting of a first and last name. Names that are random or not plausible as real names are prohibited.
					</p>
				</li>
				<li>
					<p className="legal-title">Terrorism Roleplay</p>
					<p className="legal-description">
						Roleplaying as a terrorist, part of a terrorist organisation, or any group with known ties to terrorism, either past or present, is strictly forbidden. This includes both domestic and international terrorism.
					</p>
				</li>
				<li>
					<p className="legal-title">Camping and Exploiting Locations</p>
					<p className="legal-description">
						Camping at illegal locations with the intention of committing crimes such as robbery or kidnapping is not allowed unless explicitly pre-planned as part of a roleplay scenario.
					</p>
				</li>
				<li>
					<p className="legal-title">Emergency Service Character Switching</p>
					<p className="legal-description">
						Players cannot switch to their emergency service character (RAS/Police) within 20 minutes of committing a criminal act.
					</p>
				</li>
				<li>
					<p className="legal-title">Stealing Emergency Vehicles</p>
					<p className="legal-description">
						Stealing an emergency vehicle is only permitted if at least five police officers are online.
					</p>
				</li>
				<li>
					<p className="legal-title">Stealing Emergency Equipment</p>
					<p className="legal-description">
						The theft of emergency equipment, such as handcuffs, firearms, or police radios, is prohibited.
					</p>
				</li>
				<li>
					<p className="legal-title">Interfering with Active Scenes</p>
					<p className="legal-description">
						Players are not allowed to interfere with active roleplay scenes such as robberies or hostage situations unless they are a part of the original storyline. However, players may intervene if a robbery is about to take place and no hostages are involved.
					</p>
				</li>
				<li>
					<p className="legal-title">Impersonating Emergency Responders</p>
					<p className="legal-description">
						Impersonating a police officer or paramedic without explicit management authorisation is not allowed.
					</p>
				</li>
				<li>
					<p className="legal-title">Committing Criminal Offences</p>
					<p className="legal-description">
						Players cannot exit the server or travel to inaccessible areas immediately after committing a criminal act.
					</p>
				</li>
				<li>
					<p className="legal-title">Driving Offences and Active Pursuits</p>
					<p className="legal-description">
						Players cannot repair their vehicles during an active police pursuit. Additionally, interfering with pursuits, such as ramming police vehicles, is not allowed.
					</p>
				</li>
				<li>
					<p className="legal-title">Civilian Driving Standards</p>
					<p className="legal-description">
						All players must drive responsibly and follow basic traffic laws. Reckless driving or driving in a manner that endangers the lives of others, without any roleplay purpose, is not allowed.
					</p>
				</li>
				<li>
					<p className="legal-title">Forcing Asset Transfers</p>
					<p className="legal-description">
						Players cannot force others to transfer money or assets unless there is a legitimate in-game reason for the transaction, such as during a robbery or a roleplay event.
					</p>
				</li>
				<li>
					<p className="legal-title">Random Events</p>
					<p className="legal-description">
						When participating in large-scale scenarios such as bank robberies, kidnapping, heists, players must follow a clear storyline. Random or spontaneous large-scale events, such as unprovoked mass attacks, are not allowed.
					</p>
				</li>
				<li>
					<p className="legal-title">Stream Sniping</p>
					<p className="legal-description">
						Players who are streaming their gameplay must not be targeted or attacked based solely on the fact they are streaming. Players must avoid stream sniping and any activity that takes advantage of the information viewers can see.
					</p>
				</li>
				<li>
					<p className="legal-title">Sexual Roleplay</p>
					<p className="legal-description">
						Any form of explicit, sexually suggestive, or inappropriate roleplay is strictly prohibited. This includes in-game actions, text-based interactions or any visual representations.
					</p>
				</li>
				<li>
					<p className="legal-title">Multi Charactering</p>
					<p className="legal-description">
						You cannot use multiple characters to manipulate game mechanics, bypass restrictions or engage in unfair activity. For example, transferring money from one character to another.
					</p>
				</li>
				<li>
					<p className="legal-title">Lootboxing</p>
					<p className="legal-description">
						Killing other players solely to collect their items without meaningful roleplay context is not allowed. All combat should have an in-character justification.
					</p>
				</li>
			</ol>

			<h2>Roleplay Definitions</h2>

			<ol className="legal-list">
				<li>
					<p className="legal-title">OOC (Out of Character)</p>
					<p className="legal-description">
						Using language or information outside of the roleplay context. Players should avoid discussing real-world issues or engaging in off-topic conversation during roleplay sessions.
					</p>
				</li>
				<li>
					<p className="legal-title">FailRP</p>
					<p className="legal-description">
						This occurs when a player performs an action that is unrealistic or breaks immersion in the roleplay scenario. For example, driving a car at high speeds without any logical reason.
					</p>
				</li>
				<li>
					<p className="legal-title">RDM (Random Deathmatch)</p>
					<p className="legal-description">
						Killing another player without any in-game reason or proper roleplay initiation. This includes killing players at random without trying to engage them in a roleplay scenario.
					</p>
				</li>
				<li>
					<p className="legal-title">VDM (Vehicle Deathmatch)</p>
					<p className="legal-description">
						Using a vehicle as a weapon to kill or incapacitate other players without a valid roleplay reason. This type of behaviour disrupts the roleplay environment and is strictly prohibited.
					</p>
				</li>
				<li>
					<p className="legal-title">GOR (Gunplay Over Roleplay)</p>
					<p className="legal-description">
						Prioritising combat and shooting over developing meaningful storylines and interactions. Roleplay should be about story development, and combat should always have context and purpose.
					</p>
				</li>
				<li>
					<p className="legal-title">Combat Logging</p>
					<p className="legal-description">
						Exiting the server or disconnecting to avoid combat, death, or other in-game consequences. Once engaged in a roleplay scenario, players are expected to fully commit until the RP is ended.
					</p>
				</li>
				<li>
					<p className="legal-title">Metagaming</p>
					<p className="legal-description">
						The act of using knowledge acquired outside of the roleplay environment (such as from OOC discussions or external sources) to influence in-game actions. This breaks immersion and is strictly forbidden.
					</p>
				</li>
				<li>
					<p className="legal-title">Powergaming</p>
					<p className="legal-description">
						Powergaming refers to forcing actions or events to go in your favour without allowing others to influence the outcome. This includes unrealistic use of in-game items, abilities, or situations to control the roleplay without consideration for other players’ input.
					</p>
				</li>
				<li>
					<p className="legal-title">New Life Rule</p>
					<p className="legal-description">
						If your character dies, you may not return to the area of death for a minimum 20 minutes. Players must start fresh and not retain any memory or actions from the last 20 minutes.
					</p>
				</li>
			</ol>

			<h2>Gang Rules</h2>

			<ol className="legal-list">
				<li>
					<p className="legal-title">Gang Approval</p>
					<p className="legal-description">
						A verified gang must be approved prior to purchasing on Tebex. A non-verified gang does not require approval but will not gain the benefits of a verified gang.
					</p>
				</li>
				<li>
					<p className="legal-title">Gang Alliances</p>
					<p className="legal-description">
						While forming alliances is permitted, these must be documented and reported to Senior Staff to avoid confusion and ensure fair interactions. Unauthorised alliances will be deemed invalid.
					</p>
				</li>
				<li>
					<p className="legal-title">Territory Claims</p>
					<p className="legal-description">
						Territory can be claimed through selling and buying drugs whilst avoiding crime in your area. Territory can be seen on the mini map. Any disputes over territories should be settled through roleplay, adhering to server rules. Forcefully claiming unregistered territories is not allowed.
					</p>
				</li>
				<li>
					<p className="legal-title">Gang Conflict</p>

					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								All planned Gang wars must be approved by Senior Staff.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Initiations must be clear and well-documented through roleplay.
							</p>
						</li>
						<li>
							<p className="legal-description">
								Killing without proper initiation or randomly attacking members of another gang will be considered RDM.
							</p>
						</li>
					</ul>
				</li>
				<li>
					<p className="legal-title">Abuse of Gang Power</p>
					<p className="legal-description">
						Gang members must not use their gang's status to intimidate or unfairly influence other players outside roleplay. Using gang affiliations to harass or provoke non-gang players in a way that disrupts their roleplay will result in consequences.
					</p>
				</li>
				<li>
					<p className="legal-title">Use of firearms in Gangs</p>
					<p className="legal-description">
						Weapons used during gang-related activities must be consistent with the roleplay setting and follow server rules. Use of high-powered weapons or explosives should be limited and realistic. Overuse may result in consequences.
					</p>
				</li>
				<li>
					<p className="legal-title">Recruiting Gang Members</p>
					<p className="legal-description">
						Recruitment must be done in roleplay, out of game recruitment is not allowed.
					</p>
				</li>
				<li>
					<p className="legal-title">Breaching Gang Rules</p>
					<p className="legal-description">
						Any gang found violating the above rules will face escalating consequences. Initial offences may only result in warnings or suspension of activities, whilst repeated offences could lead to disbandment or bans.
					</p>
				</li>
			</ol>

			<h2>Business Rules</h2>

			<ol className="legal-list">
				<li>
					<p className="legal-title">Business Registration</p>
					<p className="legal-description">
						All player-owned businesses are either bought through Tebex or using in-game currency at a designated shop location. Operating an unregistered business that falls out of this criterion is not allowed and will result in consequences.
					</p>
				</li>
				<li>
					<p className="legal-title">Business Roleplay</p>
					<p className="legal-description">
						All business operations must adhere to high standards of roleplay. Players running businesses should ensure their activities contribute meaningfully to the roleplay and maintain realistic interactions with customers.
					</p>
				</li>
				<li>
					<p className="legal-title">Business Competition</p>
					<p className="legal-description">
						All businesses must engage in fair competition. Sabotaging or harassing rival businesses, either through game mechanics or external methods is not allowed. Roleplay conflicts are encouraged but must remain within acceptable and realistic boundaries.
					</p>
				</li>
				<li>
					<p className="legal-title">Illegal Activities</p>
					<p className="legal-description">
						Businesses used as a front for illegal activities must be approved by Senior Staff and integrated into the roleplay narrative. Cover businesses must maintain a realistic balance between legitimate and illicit activities to avoid breaking immersion.
					</p>
				</li>
				<li>
					<p className="legal-title">Handling Disputes</p>
					<p className="legal-description">
						Disputes between businesses or between a business and its customers should be resolved through roleplay if possible. If an issue escalates beyond the capacity for roleplay resolution, staff may intervene to meditate the dispute. Repeated abuse of staff time for minor disputes will be dealt with.
					</p>
				</li>
				<li>
					<p className="legal-title">Product Legitimacy</p>
					<p className="legal-description">
						All products and services offered by player-owned businesses must align with server rules. Businesses must avoid selling or promoting items or services that break immersion or provide unfair advantages, such as exploitative game items or banned modifications. The products must also be relevant to your business, i.e. selling mechanic parts at a taco restaurant is not allowed.
					</p>
				</li>
			</ol>

			<h2>Emergency Services Rules</h2>

			<ol className="legal-list">
				<li>
					<p className="legal-title">Professional RP</p>
					<p className="legal-description">
						All members of emergency services must maintain a high standard of roleplay at all times. This includes realistic interactions, appropriate communication, and adhering to your divisional policies. Unprofessional behaviour or breaking character during interactions may result in disciplinary action.
					</p>
				</li>
				<li>
					<p className="legal-title">Chain of command</p>
					<p className="legal-description">
						Emergency service members must respect the chain of command. Orders issued from higher-ranking officers or paramedics must be followed unless they directly conflict with server rules.
					</p>
				</li>
				<li>
					<p className="legal-title">Use of force</p>
					<p className="legal-description">
						Use of force must be realistic and proportionate to the situation. Excessive or unjustified use of force, such as shooting unarmed or compliant individuals without proper escalation, is not allowed.
					</p>
				</li>
				<li>
					<p className="legal-title">Corruption Roleplay</p>
					<p className="legal-description">
						Corruption within emergency services is not allowed without authorisation from Management. Emergency services are expected to uphold a standard of integrity and realism that aligns with their role in the server. Any attempts to engage in or promote corrupt activities will lead to immediate action, up to and including removal from said service.
					</p>
				</li>
				<li>
					<p className="legal-title">Radio Comms</p>
					<p className="legal-description">
						All personnel must maintain clear, concise and professional communication always. This includes using state codes and terminology where appropriate. The following police radio channels are available:
					</p>
					<ul className="legal-sub-list">
						<li>
							<p className="legal-description">
								<strong>CH1 Inter Ops 1</strong> (Police/RAS)
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>CH2 Ops 1</strong> (Police)
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>CH3 Ops 2</strong> (RAS)
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>CH4 Pursuit 1, CH5 Pursuit 2</strong> (Police)
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>CH6 Incident 1, CH7 Incident 2</strong> (Police/RAS)
							</p>
						</li>
						<li>
							<p className="legal-description">
								<strong>CH8 Reserve 1, CH9 Reserve 2, CH10 Reserve 3</strong> (Police/RAS)
							</p>
						</li>
					</ul>
				</li>
			</ol>
		</div>
	)
}

export default Rules;