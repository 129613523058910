import { useEffect, useState } from "react";
import { ListUserResponse, UserSummary } from "./user.models";
import { ListUsers } from "./user.api";
import { AppResponseWithContent } from "../../AppResponse";
import BannerMessage, { BannerMessageType } from "../../Components/BannerMessage/BannerMessage";
import { BsCaretLeftFill, BsCaretRightFill } from "react-icons/bs";

import "./UserTable.css"
import { useNavigate } from "react-router-dom";

const UserTable = () => {
	const [users, setUsers] = useState<UserSummary[]>();
	const [searchTerm, setSearchTerm] = useState<string | null>(null);
	const [isSuspended, setIsSuspended] = useState<boolean | null>(null);
	const [pageIndex, setPageIndex] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(10);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [failure, setFailure] = useState<string>("");
	const navigate = useNavigate();

	useEffect(() => {
		async function apiCall() {
			try {
				const appResponse: AppResponseWithContent<ListUserResponse> = await ListUsers({
					searchTerm: searchTerm,
					isSuspended: isSuspended,
					pageIndex: pageIndex,
					pageSize: pageSize
				})

				if (appResponse.statusCode === 200) {
					setUsers(appResponse.content?.content)
					setTotalPages(appResponse.content?.totalPages ?? 1)
					setPageSize(appResponse.content?.pageSize ?? 10)
				}
				else {
					let errorMessage = "Unknown error occured.";

					if (appResponse.statusCode === 401) {
						errorMessage = "Failed to authenticate discord user. Please try again later."
					}

					setFailure(appResponse.message ?? errorMessage);
				}
			}
			catch (err) {
				setFailure("Unknown error occured.");
			}
		}

		apiCall();
	}, [setUsers, setTotalPages, setPageSize, pageIndex, searchTerm]);

	const onClick = (id: number) => navigate(`/users/user?userId=${id}`)

	const onPaginate = (direction: string) => {
		if (direction === "left") {
			if (pageIndex > 1) {
				setPageIndex(pageIndex - 1)
			}
		}

		if (direction === "right") {
			if (pageIndex < totalPages) {
				setPageIndex(pageIndex + 1)
			}
		}
	}

	return (
		<>
			{
				failure.length > 0 ?
					<BannerMessage closable={true} description={failure} title="Oh no!" type={BannerMessageType.Error} />
					:
					<></>
			}

			<h1>Users</h1>

			<div className="tableControls">
				<input className="searchInput" name="searchTerm" placeholder="e.g Josh" onChange={(input) => setSearchTerm(input.target.value)} />
				<div className="paginationButton" onClick={() => onPaginate("left")}>
					<BsCaretLeftFill />
				</div>

				<div className="paginationInfo">
					<p>{pageIndex}/{totalPages}</p>
				</div>

				<div className="paginationButton" onClick={() => onPaginate("right")}>
					<BsCaretRightFill />
				</div>
			</div>

			<table>
				<thead>
					<tr>
						<th>Discord ID</th>
						<th>Name</th>
						<th>Is Suspended</th>
					</tr>
				</thead>
				<tbody>
					{users?.map((user) => (
						<tr className="table-item" key={user.id} onClick={() => onClick(user.id)}>
							<td>{user.discordId}</td>
							<td>{user.name}</td>
							<td>
								{user.isSuspended ? "Yes" : "No"}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	)
}

export default UserTable;