import { useEffect, useState } from "react";

import { CacheKeys } from "../../CacheKeys";
import LinkCard, { LinkCardContents } from "../../Components/LinkCard/LinkCard";
import { UserPermissionsEn } from "../../Pages/user/user.models";

import Config from "../../config.json";

import "./SideNavigation.css"
import { useLocation } from "react-router-dom";

const SideNavigation = () => {
  const [cards, setCards] = useState<LinkCardContents[]>([]);
  const location = useLocation();

  useEffect(() => {
    getCards();
  }, [setCards, location]);

  const getCards = () => {
    let cards: LinkCardContents[] = [];

    cards.push({
      text: "Home",
      url: "/"
    });

    cards.push({
      text: "Guides",
      url: "/guides"
    });

    if (localStorage.getItem(CacheKeys.AuthToken) === null ||
      localStorage.getItem(CacheKeys.AuthToken) === "") {
      cards.push({
        text: "Login",
        url: Config.discordOAuth2Url
      });
    }
    else {
      if (localStorage.getItem(CacheKeys.Permissions) !== null &&
        localStorage.getItem(CacheKeys.Permissions) !== "") {

        let permissions: UserPermissionsEn[] = localStorage.getItem(CacheKeys.Permissions) === null ? {} : JSON.parse(localStorage.getItem(CacheKeys.Permissions)!);

        if (permissions.includes(UserPermissionsEn.ReadUsers)) {
          cards.push({
            text: "Users",
            url: "/users"
          });
        }
      }

      cards.push({
        text: "Logout",
        url: "/users/logout"
      });
    }

    setCards(cards);
  }

  return (
    <nav className="sideNav">
      {
        cards.map((card, i) => <LinkCard text={card.text} key={i} url={card.url} />)
      }
    </nav>
  )
}

export default SideNavigation;