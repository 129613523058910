import { Link, useLocation } from "react-router-dom";

import "./LinkCard.css";
import { useEffect, useState } from "react";

export type LinkCardContents = {
	url: string,
	text: string
}

const LinkCard = (props: LinkCardContents) => {
	const [current, setCurrent] = useState<boolean>(false);
	const location = useLocation();

	useEffect(() => {
		setCurrent(window.location.pathname.toLowerCase() === props.url.toLowerCase());

	}, [setCurrent, location, props.url])

	return (
		<Link to={props.url} className="link-card">
			<div className={["link-card-container", `${current ? "link-card-selected" : ""}`].join(" ")}>
				<p className={"link-card-text"}>{props.text}</p>
			</div>
		</Link>
	);
}

export default LinkCard;